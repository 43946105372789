
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



ul {
  list-style: none
}



li::before {
  content: "\2022";
  color: #FA4A0C;
  display: inline-block;
  width: 1em;
  margin-left: -0.5em;
}


@font-face {
  font-family: customFont;
  src: url(VarelaRound-Regular.ttf)
}


@font-face {
  font-family: customFont2;
  src: url(VarelaRound-Regular.ttf)
}

@font-face {
  font-family: customFont3;
  src: url(VarelaRound-Regular.ttf)
}


body {
  background-color: #111827;
  background-color: white;
  font-family: customFont;

}


/*
contact styling
*/

#contactview-container {
backdrop-filter: blur(3px);
-webkit-backdrop-filter: blur(3px);
z-index: 1;
display: flex;
width: 100vw;
height: 100vh;
position: fixed;
top: 0px;
bottom: 0px;
justify-content: center;
align-items: center;
z-index: 3;
overflow:scroll;
padding: 20px;
}

#contact-option-card {
  column-gap: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200.29px;
  height:90.62px;

}

#contact-card {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  row-gap: 20px;
  position: relative;
  padding: 30px;
  margin-inline: auto;
  height: 300px;
  border-radius: 40px;
  background-color: rgb(29, 29, 31, .95);
  box-shadow: 0px 25.704px 48.704px 10.352px rgba(0, 0, 0, .35);
}

#bubble-menu-body {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  background: rgb(29, 29, 31, 1);
   border-radius: 50px;
    padding: 10px;
    background-color: rgb(29, 29, 31, .95);
    box-shadow: 0px 25.704px 48.704px 10.352px rgba(0, 0, 0, .35);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    
}

#bubble-button {
  display: flex;

  justify-content: center;

  align-items: center;
  border: none;
  background-color: rgb(29, 29, 31, 1);
  width: 40px;
  height: 40px;
  border-radius: 50px;
  position: absolute;
  z-index: 10;
  transform: translate(15px,15px);
}

#contact-card  {

  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}



/*
Experience view styling
*/

#experience-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  max-width: 804px;
  height: 'auto';
  background-color: 'white';
  padding: 20px;
  row-gap: 10px
}

#job-info-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  font-family: customFont2;
}

#job-achievements-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

#experience-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px
}

/*
Landing view styling
*/

#landing-view-container {
  width: 100%;
  height: auto;
  flex-direction: column;
  background-color: white;
  display: flex;
    justify-content: center;
    align-items: center;
  min-height: 100vh;

}

#bio-container {
  padding: 20px;
  display: grid;
  grid-template-columns: 10rem 10rem 10rem;
  grid-template-rows: auto auto auto;
  column-gap: 10px;
  row-gap: 10px;
  padding-top: 50px;

}

.bento-item {
  place-self: center stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: rgb(29, 29, 31);
  height: 10rem;
  overflow: hidden;
  padding: 30px;
  width: 100%;
  flex-direction: column;
  background-color: rgb(29, 29, 31, 1);
  box-shadow: 0px 10.704px 48.704px .352px rgba(0, 0, 0,.11);


}

.bento-item:nth-child(1) {
  grid-column: 1/span 2;

  position:relative;
}

.bento-item:nth-child(2) {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;



  position:relative;
}

.bento-item:nth-child(4) {
  grid-column: 2/span 2;
}

.bento-item:nth-child(3) {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 500px) {
  #bio-container  {
    grid-template-columns: auto;
    grid-auto-rows: auto;

 
}
}
/*
sidebar styling
*/

#sidebar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 100%
}

#sidebar {
  background-color: rgb(29, 29, 31);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, .15);
  position: fixed;
  top: 10px;
  min-height: 80px;
  border-radius: 21.31px;
  width: calc(100% - 20px);
  max-width: 700px;
  overflow: hidden;
  z-index:2;
}

#expanded-sidebar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  width: 100%;
  height: 100%;
}

#top-sidebar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:  center;
  width: 100%;
  position: absolute;
  top: 26px;
}

/*
project view
*/

#project-view-container {
  flex-direction: column;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center
}
.project-card {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 120px;

}
#gallery-container {
  display: flex;
   width: 100%;
   justify-content: center;
    align-items: center;
}
/*
project detail
*/

.view-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: none;
  border: none;
}

.project-feature-summary {
  color: white;
  font-family: customFont;
}
.project-grid-item {
  place-self: center stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: rgb(29, 29, 31);
  height: 100%;
  overflow: hidden;
  padding: 30px;
  box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);
  width: 100%;
  flex-direction: column;
}



#project-detail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

#project-gall-container {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  flex-direction: row;
  align-items: flex-end;
  column-gap: 50px;
  padding: 30px;
}

#project-gall-container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.project-feature-veil {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(29, 29, 31, 0.3);
  backdrop-filter: blur(2px);
-webkit-backdrop-filter: blur(2px);
display: flex;
justify-content: flex-start;
padding: 30px;
align-items: flex-start;

}

#project-grid-container {
  display: grid;
  grid-template-columns: 19rem 5rem 19rem;
  grid-template-rows: auto auto auto auto;
  column-gap: 10px;
  row-gap: 10px;

}
.project-grid-item {
  min-height: 473px;
  grid-column: 1/span 2;
  grid-row: 1/span 1; 
  position:relative;
}

.project-grid-item:nth-child(2) {
  grid-column: 3/span 1;
  grid-row: 1/span 1; 
}

.project-grid-item.web {
  grid-column: 1/3 ;
  grid-row: 1;

}

.project-grid-item.web:nth-child(2) {
  grid-column: 3/4;
  grid-row: 0;

}

.project-grid-item:nth-child(3) {
  grid-column: auto/span 3;
  grid-row: auto/span 1; 
  
}

.project-grid-item.musie:nth-child(3) {
  grid-column: auto/span 2;
  grid-row: auto/span 1; 
  
}

.project-grid-item.web:nth-child(3) {
  grid-column: 1/4;
  grid-row: 4;

}
.project-grid-item.musie:nth-child(6) {
  grid-column: 3/span 1;
  grid-row: 3/span 1; 

}

.project-grid-item.musie:nth-child(7) {
  grid-column: auto/ span 3;
  grid-row: 4/span 1; 

}

.project-grid-item:nth-child(4) {
  grid-column: 2/span 2;
  grid-row: 2/span 1; 
}
.project-grid-item:nth-child(5) {
  grid-column: 1/span 1;
  grid-row: 2/span 1; 
}

.project-grid-item.web:nth-child(4) {
  grid-column: 1/4;
  grid-row: 5;

}

.project-grid-item.web:nth-child(5) {
  grid-column: 1/4;
  grid-row: 6;

}



@media only screen and (max-width: 730px) {
  #project-grid-container  {
    grid-template-columns: auto;
    grid-auto-rows: auto;
    max-width: 25em;
    width: 100%;
}
#project-grid-container  * {
  grid-column: auto;
  grid-row: auto;
}


}
@media only screen and (min-width: 930px) {
  #project-grid-container  {
    grid-template-columns: 19rem 14rem 19rem;
  }

}



